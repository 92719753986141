import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { PrimaryButton, SecondaryButton } from '../../components';

import { industryIcon } from './SectionTitle';

import { PortfolioContent } from './ListingPage';
import css from './ListingPage.module.css';
import PDFSVG from '../../assets/pdf.svg';

const ListingPageFlexRight = props => {
  const {
    richTitle,
    industry,
    city,
    isPendingApproval,
    onContactUser,
    connectionLoading,
    isOwnListing,
    isConnected,
    setImageCarouselOpen,
    images,
    positionTitle,
    company,
    university,
    graduationYear,
    degree,
    primarySpecialty,
    secondarySpecialty,
    style,
    program,
    expertise,
    licensesAndCertification,
    intl,
    currentUserIsTalentType,
    toPDF,
  } = props;

  return (
    <div className={css.listingPageDisplayRight}>
      <div className={css.sectionPortfolioTitleContent}>
        <div>
          <div className={css.sectionPortfolioTitle}>{richTitle}</div>
        </div>
        {industryIcon(industry)}
      </div>
      <div className={css.sectionPortfolioTitleDescription}>
        <div>{city + ', '}</div>
        <div> {industry}</div>
      </div>

      <div className={css.sectionPortfolioButtonContent}>
        {!isPendingApproval ? (
          <PrimaryButton
            type="button"
            disabled={isOwnListing || isConnected}
            onClick={onContactUser}
            inProgress={connectionLoading}
          >
            <FormattedMessage
              id={
                isConnected
                  ? `ListingPage.${currentUserIsTalentType}.connected`
                  : `ListingPage.${currentUserIsTalentType}.connect`
              }
            />
          </PrimaryButton>
        ) : null}
        <SecondaryButton
          type="button"
          onClick={() => {
            setImageCarouselOpen(true);
          }}
          disabled={images.length === 0}
        >
          <FormattedMessage id="ListingPage.ListingPageFlexRight.viewPortfolioButton" />
        </SecondaryButton>
        <SecondaryButton className={css.pdf} onClick={() => toPDF()}>
          <img src={PDFSVG} width={28} /> PDF
        </SecondaryButton>
      </div>

      {positionTitle ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentCompany',
          })}
        >
          {positionTitle ? (
            <div className={css.sectionPortfolioTopicTitle}>{positionTitle}</div>
          ) : null}
          {company ? (
            <div className={css.sectionPortfolioTopicDescription}>at {company}</div>
          ) : (
            <div className={css.sectionPortfolioTopicDescription}>at NA</div>
          )}
          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}

      {university || graduationYear || degree ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentEducation',
          })}
        >
          <div className={css.sectionPortfolioFlex}>
            {university ? (
              <div>
                <div className={css.sectionPortfolioTopicTitle}>
                  <FormattedMessage id="ListingPage.university" />
                </div>
                <div>{university}</div>
              </div>
            ) : null}
            {graduationYear ? (
              <div>
                <div className={css.sectionPortfolioTopicTitle}>
                  <FormattedMessage id="ListingPage.graduationYear" />
                </div>
                <div>{graduationYear}</div>
              </div>
            ) : null}
          </div>
          {degree ? (
            <div>
              <div className={css.sectionPortfolioTopicTitle}>
                <FormattedMessage id="ListingPage.degree" />
              </div>
              <div>{degree}</div>
            </div>
          ) : null}

          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}

      {primarySpecialty || (secondarySpecialty && secondarySpecialty.length > 0) ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentSPECIALTIES',
          })}
        >
          <div className={css.sectionPortfolioFlex}>
            {primarySpecialty ? (
              <div>
                <div className={css.sectionPortfolioTopicTitle}>
                  <FormattedMessage id="ListingPage.primary" />
                </div>
                <div>{primarySpecialty}</div>
              </div>
            ) : null}
            {secondarySpecialty && secondarySpecialty.length > 0 ? (
              <div>
                <div className={css.sectionPortfolioTopicTitle}>
                  <FormattedMessage id="ListingPage.secondary" />
                </div>
                <div>{secondarySpecialty ? secondarySpecialty.join(', ') : null}</div>
              </div>
            ) : null}
          </div>
          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}

      {style && Array.isArray(style) && style.length > 0 ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentSTYLES',
          })}
        >
          <div className={css.listCardMiddle}>
            {style &&
              style.map((v, index) => (
                <div key={index} className={css.listingCardDetails}>
                  {v}
                </div>
              ))}
          </div>
          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}

      {program && Array.isArray(program) && program.length > 0 ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentSKILLS',
          })}
        >
          <div className={css.listCardMiddle}>
            {program &&
              program.map((v, index) => (
                <div key={index} className={css.listingCardDetails}>
                  {v}
                </div>
              ))}
          </div>
          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}

      {expertise && Array.isArray(expertise) && expertise.length > 0 ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentEXPERTISE',
          })}
        >
          <div className={css.listCardMiddle}>
            {expertise &&
              expertise.map((v, index) => (
                <div key={index} className={css.listingCardDetails}>
                  {v}
                </div>
              ))}
          </div>
          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}

      {licensesAndCertification &&
      Array.isArray(licensesAndCertification) &&
      licensesAndCertification.length > 0 ? (
        <PortfolioContent
          title={intl.formatMessage({
            id: 'ListingPage.ListingPageFlexRight.PortfolioContentLICENSES',
          })}
        >
          <div className={css.listCardMiddle}>
            {licensesAndCertification &&
              licensesAndCertification.map((v, index) => (
                <div key={index} className={css.listingCardDetails}>
                  {v}
                </div>
              ))}
          </div>
          <div className={css.sectionPortfolioDivider} />
        </PortfolioContent>
      ) : null}
    </div>
  );
};

export default ListingPageFlexRight;
